import { render, staticRenderFns } from "./Testimonial.vue?vue&type=template&id=c47877a4&scoped=true"
import script from "./Testimonial.vue?vue&type=script&lang=js"
export * from "./Testimonial.vue?vue&type=script&lang=js"
import style0 from "./Testimonial.vue?vue&type=style&index=0&id=c47877a4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c47877a4",
  null
  
)

/* custom blocks */
import block0 from "@/locales/testimonial.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Ftestimonial%2FTestimonial.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/card-content-testimonial.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Ftestimonial%2FTestimonial.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports