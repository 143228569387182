<i18n src="@/locales/testimonial.json" />
<i18n src="@/locales/card-content-testimonial.json" />

<template>
  <div>
    <header>
      <div class="columns is-mobile is-centered">
        <div class="column is-12 is-narrow">
          <figure class="header__video">
            <iframe
              class="header__video-iframe"
              width="560"
              height="315"
              :src="video.src"
              frameborder="0"
              allowfullscreen
            />
          </figure>
        </div>
      </div>
    </header>
    <b-message>
      <p>
        {{ $t('message_1') }}
      </p>
    </b-message>
    <section class="p-1 fixed-background">
      <div class="columns is-multiline">
        <div
          v-for="contentCardTesti in contentCardTestimonial"
          :key="contentCardTesti.id"
          class="column is-3-desktop-only is-4"
        >
          <ImanPictureBox
            :content="contentCardTesti"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: 'Testimonial',
  mixins: [metaMixin],
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  data () {
    return {
      video: {
        src: 'https://www.youtube.com/embed/713MspC3i2U'
      },
      mainBackgroundClass: 'none-background',
      overlayClass: 'overlay-half-circle',
      serviceClass: {
        overlay: '.overlay-half-circle .show-overlay-half-circle',
        element: 'element show-element'
      }
    }
  },
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../../assets/img/thumbnail/MINIATURE_3.0.png')
    },
    contentCardTestimonial () {
      switch (this.$i18n.locale) {
        case 'en':
          return Object.values(this.$i18n.messages.en.contentCardTestimonial)
            .filter(testimonialCardContent => testimonialCardContent)
        case 'ru':
          return Object.values(this.$i18n.messages.ru.contentCardTestimonial)
            .filter(testimonialCardContent => testimonialCardContent)
        default:
          return Object.values(this.$i18n.messages.fr.contentCardTestimonial)
            .filter(testimonialCardContent => testimonialCardContent)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .header__video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
  }

  .header__video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

</style>
