<i18n src="@/locales/articles-card-content.json"/>

<template>
  <div>
    <ImanH2 class="mt-0 mb-0 mr-1 ml-1">{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent"/>
  </div>
</template>

<script>

import {metaMixin} from "@/mixins/meta-mixin";
import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";

export default {
  name: 'Article',
  mixins: [
    articlesCardContentFilterMixin,
    metaMixin
  ],
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  data() {
    return {
      articlesCardContentId: [
        81,
        82,
        83,
        84,
        85,
        86,
        88,
        89,
        810,
        20,
        21,
        211,
        2111,
        212,
        22,
        213,
        221,
        222,
        223,
        224,
        23,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        40,
        41,
        42,
        43,
        44,
        46,
        47,
        52,
        53,
        531,
        532,
        533,
        534,
        214,
        2241,
        2242,
        2243,
        2244,
        2245,
        2246,
      ],
    }
  },
  computed: {
    openGraphImgPath() {
      return 'https://anveli.dental' + require('../../assets/img/thumbnail/MINIATURE_3.0.png')
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  @media only screen and (min-width: 768px) {
    /* tablet et ordinateurs */
    background-image: url("../../assets/img/dental-implant/basal-implant/H1-implant-basal-d.jpg");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
  }
  @media only screen and (max-width: 767px) {
    /* smartphone en mode paysage */
    background-image: url("../../assets/img/dental-implant/basal-implant/H1-implant-basal-t.jpg");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
  }
  @media only screen and (max-width: 767px) and (orientation: portrait) {
    /* smartphone en mode portait */
    background-image: url("../../assets/img/dental-implant/basal-implant/H1-implant-basal-m.jpg");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
  }
}
</style>
